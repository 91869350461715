<template>
  <div class="bg-black h-full">
    <div class="container mx-auto lg:py-20 py-10 text-white px-6 lg:px-0">
      <div class="flex flex-col items-center mb-18">
        <h1 class="text-4.5xl font-bold mb-8">Guestio talent</h1>
        <p class="text-xl mb-18">A selection of out top-talented guests & shows</p>

        <div class="w-10/12 sm:w-6/12 md:w-3/12  rounded-full text-white bg-blue-800 shadow-xl">
          <button @click="selectType('guest')"
                  :class="profilesType === 'guest' ? 'font-semibold rounded-full  bg-indigo-gradiant' : 'rounded-l-full bg-blue-800 font-light text-guestio-gray-300'"
                  class="text-center w-1/2 py-3 text-xs md:text-base">Guests
          </button>
          <button @click="selectType('show')"
                  :class="profilesType === 'show' ? 'font-semibold rounded-full  bg-indigo-gradiant' : 'rounded-r-full bg-blue-800 font-light text-guestio-gray-300'"
                  class="rounded-r-full text-center w-1/2 py-3 text-xs md:text-base">Shows
          </button>
        </div>
      </div>

      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mb-12">
        <Item :profile="profile" v-for="(profile, index) in profiles" :key="`${profilesType}-${index}-${profile.id}`"/>

        <template v-if="loading && !profiles.length">
          <profile-skeleton
            v-for="i in 8"
            primary-color="#F5F5F5"
            secondary-color="#FAFAFA"
            :key="`skeleton-${i}`"
          />
        </template>
      </div>

      <div v-if="pagination.total_pages > 1" class="pagination my-12 flex sm:justify-end">
        <nav class="h-10 flex items-center text-white w-full sm:w-auto" role="pagination">
          <button @click="goToPrevPage" class="opacity-40 w-full sm:w-10 h-full flex items-center justify-center border border-guestio-gray-100 rounded-l-xl hover:opacity-60 transition ease-in-out duration-150">
            <ChevronLeftAltIcon class="h-5" />
          </button>

          <button
            v-for="page in paginationRange"
            :key="page"
            @click="goToPage(page)"
            :class="{'opacity-40': page !== pagination.current_page}"
            class=" w-full sm:w-10 h-full flex items-center justify-center border"
          >
            {{ page }}
          </button>

          <button v-if="pagination.links.next" @click="goToNextPage" class="opacity-40  w-full sm:w-10 h-full flex items-center justify-center border border-guestio-gray-100 rounded-r-xl hover:opacity-60 transition ease-in-out duration-150">
            <ChevronRightAltIcon class="h-5" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>

import { range } from 'lodash';
import api from '@/api';
import Item from "@/components/home/Item";
import ProfileSkeleton from '@/components/shared/ProfileSkeleton';
import ChevronLeftAltIcon from '@/components/svgs/ChevronLeftAltIcon';
import ChevronRightAltIcon from '@/components/svgs/ChevronRightAltIcon';

export default {
  name: "GuestioTalent",
  components: { Item, ProfileSkeleton, ChevronLeftAltIcon, ChevronRightAltIcon },
  data() {
    return {
      profilesType: 'guest',
      profiles: [],
      loading: true,
      pagination: {
        total: 0,
        count: 0,
        per_page: 15,
        current_page: 1,
        total_pages: 1,
        links: {}
      },
    }
  },

  computed: {
    paginationRange() {
      if (this.pagination.total_pages <= 5) {
        return this.pagination.total_pages
      }

      if (this.pagination.current_page > 3) {
        const from = this.pagination.current_page - 2;
        const nextPagesAmount = this.pagination.total_pages - this.pagination.current_page;
        const to = nextPagesAmount > 2 ? 3 : nextPagesAmount;
        return range(from, this.pagination.current_page + to + 1);
      }

      return 5
    }
  },

  mounted() {
    this.loadProfiles();
  },
  
  methods: {
    selectType(type = null) {
      if (type) {
        this.profilesType = type;
      }
      this.currentPage = 1;
      this.loadProfiles();
    },

    loadProfiles(page = 1) {
      this.loading = true;
      this.profiles = [];
      api.get(`/guestio-talent?type=${this.profilesType}&page=${page}`)
        .then(({data}) => {
          if (data.data.length) {
            this.profiles = data.data;
            this.pagination = data.meta.pagination;
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },

    goToNextPage() {
      if (!this.pagination.links.next) {
        return;
      }
      this.loadProfiles(this.pagination.current_page + 1);
    },

    goToPrevPage() {
      this.loadProfiles(!this.pagination.links.previous ? 1 : this.pagination.current_page - 1);
    },

    goToPage(pageNo) {
      this.loadProfiles(pageNo);
    },
  },
}
</script>

